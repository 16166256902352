<template>
  <app-side-bar v-model="open">
    <template #actions>
      <v-btn
        v-if="ownFacility"
        icon
        data-cy="editUnitIcon"
        @click="editUnit()"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <template #headerTitle>
      <v-toolbar-title
        class=""
        style="line-height: 1.2rem"
      >
        <div
          v-if="unit.facility"
          class="text-truncate text-subtitle-1 pa-0"
        >
          {{ formatFacilityHierarchy(unit.facility) }} > {{ unit.facility.name }}
        </div>
        <span
          data-cy="breadCrumbsUnitName"
          class="text-subtitle-1 font-weight-bold"
          >{{ formatParts([unit.short_name, unit.long_name]) }}</span
        >
      </v-toolbar-title>
    </template>
    <v-container>
      <span class="text-subtitle-1"> Fachrichtung </span>

      <v-hover v-slot="{ props, isHovering }">
        <v-list-item
          v-if="unit.specialities && unit.specialities.length"
          icon
          v-bind="props"
          @click="editUnit()"
        >
          <v-list-item-subtitle
            v-for="speciality in unit.specialities"
            :key="speciality.id"
          >
            {{ speciality.speciality_id.name }}
          </v-list-item-subtitle>
          <template #append>
            <v-icon
              v-if="ownFacility && isHovering"
              size="small"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-list-item>
        <v-list-item
          v-else
          icon
          v-bind="props"
          @click="editUnit()"
        >
          <v-list-item-subtitle> Keine Fachrichtung angegeben </v-list-item-subtitle>
          <template #append>
            <v-icon
              v-if="ownFacility && isHovering"
              size="small"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-list-item>
      </v-hover>

      <v-divider class="my-2" />
      <span class="text-subtitle-1"> Rotationstyp </span>

      <v-hover v-slot="{ props, isHovering }">
        <v-list-item
          icon
          v-bind="props"
          @click="editUnit()"
        >
          <v-list-item-subtitle>
            {{ unit && unit.rotation_types ? unit.rotation_types.join(', ') : '' }}
          </v-list-item-subtitle>
          <template #append>
            <v-icon
              v-if="ownFacility && isHovering"
              size="small"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-list-item>
      </v-hover>

      <v-divider class="my-2" />
      <span class="text-subtitle-1"> Mindest- Maximalbesetzung </span>

      <v-hover v-slot="{ props, isHovering }">
        <v-list-item
          icon
          v-bind="props"
          @click="editUnit()"
        >
          <v-list-item-subtitle>
            {{ unit.minimum_vacancies }} - {{ unit.maximum_vacancies }}
          </v-list-item-subtitle>
          <template #append>
            <v-icon
              v-if="ownFacility && isHovering"
              size="small"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-list-item>
      </v-hover>

      <div v-if="getPremiumFeatures().includes('MATERNITY_PROTECTION')">
        <v-divider class="my-2" />
        <span class="text-subtitle-1"> Mutterschutz </span>
        <v-hover
          v-if="getPremiumFeatures().includes('MATERNITY_PROTECTION')"
          v-slot="{ props, isHovering }"
        >
          <v-list-item
            icon
            v-bind="props"
            @click="editUnit()"
          >
            <v-list-item-subtitle>
              {{ `Einheit ${unit.blocked_for_maternity ? 'nicht zulässig' : 'frei'}` }}
            </v-list-item-subtitle>
            <template #append>
              <v-icon
                v-if="ownFacility && isHovering"
                size="small"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-list-item>
        </v-hover>
      </div>

      <!--v-divider class="my-2" />

      <span class="text-subtitle-1"> Externe Rotanden zulassen </span>

      <v-hover v-slot="{ props, isHovering }">
        <v-list-item
          icon
          v-bind="props"
          @click="editUnit()"
        >
          <v-list-item-subtitle>
            {{ unit.takes_external_rotations ? 'Ja' : 'Nein' }}
          </v-list-item-subtitle>

          <template #append>
            <v-icon
              v-if="ownFacility && isHovering"
              size="small"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-list-item>
      </v-hover-->

      <v-divider class="my-2" />

      <span class="text-subtitle-1"> Bedingungen </span>

      <v-hover v-slot="{ props, isHovering }">
        <v-list-item
          icon
          v-bind="props"
          @click="editUnit()"
        >
          <v-list-item-subtitle>
            <schedule-flow-requirement-text
              v-if="unitId"
              class="text-subtitle-1"
              collection="unit"
              :item="unitId"
              :default-text="'Keine Bedingungen gesetzt'"
            />
          </v-list-item-subtitle>

          <template #append>
            <v-icon
              v-if="ownFacility && isHovering"
              size="small"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-list-item>
      </v-hover>
    </v-container>
  </app-side-bar>
</template>
<script>
export default {
  data() {
    return {
      unit: {},
      unitId: null,
    }
  },
  computed: {
    open: {
      get() {
        return this.$sidebarStore.unit.active
      },
      set(newValue) {
        if (!newValue) {
          this.$sidebarStore.close('unit')
        }
      },
    },
    ownFacility() {
      return this.isOwnFacility(this.unit?.facility?.id)
    },
  },
  watch: {
    '$sidebarStore.unitEdit.active'(value) {
      if (!value) {
        this.loadData()
      }
    },
  },
  async mounted() {
    this.unitId = this.$sidebarStore.unit.unit ? this.$sidebarStore.unit.unit.id : null
    await this.loadData()
  },
  methods: {
    async loadData() {
      if (this.unitId) {
        this.unit = await this.$cms.request(
          this.$readItem('unit', this.unitId, {
            fields: [
              'id',
              'short_name',
              'long_name',
              'type',
              'specialities.id',
              'specialities.speciality_id.id',
              'specialities.speciality_id.name',
              'takes_external_rotations',
              'minimum_vacancies',
              'maximum_vacancies',
              'blocked_for_maternity',
              'priority',
              'facility.id',
              'facility.name',
              'facility.show_in_hierarchy',
              'facility.parent_facility.id',
              'facility.parent_facility.name',
              'facility.parent_facility.show_in_hierarchy',
              'facility.parent_facility.parent_facility.id',
              'facility.parent_facility.parent_facility.name',
              'facility.parent_facility.parent_facility.show_in_hierarchy',
              'facility.parent_facility.parent_facility.parent_facility.id',
              'facility.parent_facility.parent_facility.parent_facility.name',
              'facility.parent_facility.parent_facility.parent_facility.show_in_hierarchy',
              'facility.parent_facility.parent_facility.parent_facility.parent_facility.id',
              'facility.parent_facility.parent_facility.parent_facility.parent_facility.name',
              'facility.parent_facility.parent_facility.parent_facility.parent_facility.show_in_hierarchy',
              'facility.parent_facility.parent_facility.parent_facility.parent_facility.parent_facility.id',
              'facility.parent_facility.parent_facility.parent_facility.parent_facility.parent_facility.name',
              'facility.parent_facility.parent_facility.parent_facility.parent_facility.parent_facility.show_in_hierarchy',
              'rotation_types',
            ],
          })
        )
      } else {
        this.unit = {}
      }
    },
    editUnit() {
      if (this.ownFacility) {
        this.$sidebarStore.open({ type: 'unitEdit', unit: { id: this.unitId } })
      }
    },
  },
}
</script>
