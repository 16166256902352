<template>
  <div v-if="facility">
    <span class="text-subtitle-1"> Verlängerung bei Teilzeit </span>
    <v-row>
      <v-col cols="12">
        <v-alert
          type="info"
          color="primary"
          variant="tonal"
          >Standardmäßig werden Rotationen in Teilzeit um den Anteil des Rotationsintervalls
          verlängert, der bis zur Vollzeitstelle fehlt (z.B.: 75% Arbeitszeit bei Rotation alle 3
          Monate &rarr; 1 Monat fehlt, wird aufgerundet um 1 Rotation verlängert).
          <v-card
            color="transparent"
            flat
            height="10"
          ></v-card>
          Geben Sie hier Bereiche von Teilzeitmodellen an, für die Sie die Verlängerung anpassen
          möchten.</v-alert
        ></v-col
      >
    </v-row>
    <v-row v-if="facility">
      <v-col cols="6">
        <v-btn
          rounded
          color="primary"
          block
          @click="
            facility.extension_config = [
              { from_percentage: 0, to_percentage: 50, extension_percentage: 50 },
              { from_percentage: 51, to_percentage: 100, extension_percentage: 100 },
            ]
          "
        >
          Verlängerung nur unter 50%
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          rounded
          color="primary"
          block
          @click="
            facility.extension_config = [
              { from_percentage: 0, to_percentage: 100, extension_percentage: 100 },
            ]
          "
        >
          Keine Verlängerung
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-table>
          <thead>
            <tr>
              <th class="text-left">Zeitmodell<br />von</th>
              <th class="text-left"><br />bis</th>
              <th class="text-left">Verlängerung <br />wie</th>
              <th class="text-left">
                Beispiel:<br />
                3 Mon.
              </th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(extension, index) in facility.extension_config"
              :key="'extension-' + index"
            >
              <td>
                <v-text-field
                  v-model="extension.from_percentage"
                  type="number"
                  step="5"
                  min="0"
                  variant="outlined"
                  suffix="%"
                  density="compact"
                  hide-details
                  width="100"
                />
              </td>
              <td>
                <v-text-field
                  v-model="extension.to_percentage"
                  type="number"
                  step="5"
                  min="0"
                  variant="outlined"
                  suffix="%"
                  density="compact"
                  hide-details
                  width="100"
                />
              </td>
              <td>
                <v-text-field
                  v-model="extension.extension_percentage"
                  type="number"
                  step="5"
                  min="0"
                  variant="outlined"
                  suffix="%"
                  density="compact"
                  hide-details
                  width="100"
                />
              </td>
              <td>+{{ Math.ceil((3 / extension.extension_percentage) * 100 - 3) }} M.</td>
              <td>
                <v-btn
                  icon
                  variant="text"
                  color="error"
                  size="small"
                  @click="facility.extension_config.splice(index, 1)"
                  ><v-icon size="large">mdi-delete</v-icon></v-btn
                >
              </td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td>
                <v-btn
                  icon
                  variant="text"
                  color="primary"
                  size="small"
                  @click="addExtension"
                  ><v-icon size="large">mdi-plus</v-icon></v-btn
                >
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { RRule } from 'rrule'
export default {
  props: {
    facilityId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      facility: null,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      if (this.facilityId) {
        this.facility = await this.$cms.request(
          this.$readItem('facility', this.facilityId, {
            fields: ['id', 'extension_config'],
          })
        )
        if (!this.facility.extension_config) {
          this.facility.extension_config = []
        }
      }
    },
    async submit() {
      if (this.areOverlapping(this.facility.extension_config)) {
        this.$notificationStore.set({
          text: 'Die Zeitmodellbereiche überlappen sich. Bitte tragen Sie nur einzelne Bereiche ohne Überschneidungen ein.',
          type: 'error',
        })
        return false
      }
      this.facility = await this.$cms.request(
        this.$updateItem(
          'facility',
          this.facilityId,
          { extension_config: this.facility.extension_config },
          {
            fields: ['id', 'extension_config'],
          }
        )
      )
      return true
    },
    areOverlapping(extensions) {
      // Sortiere die Intervalle nach ihrem Startpunkt
      extensions.sort((a, b) => a.from_percentage - b.from_percentage)

      // Überprüfe, ob zwei aufeinanderfolgende Intervalle sich überlappen
      for (let i = 0; i < extensions.length - 1; i++) {
        if (extensions[i].to_percentage > extensions[i + 1].from_percentage) {
          return true // Überlappung gefunden
        }
      }

      return false // Keine Überlappungen gefunden
    },
    addExtension() {
      this.facility.extension_config.push({
        from_percentage: 0,
        to_percentage: 100,
        extension_percentage: 50,
      })
    },
  },
}
</script>
