<template>
  <div v-if="facility">
    <span class="text-subtitle-1"> Standard-Intervall* </span>
    <schedule-recurring
      v-model="facility.schedule_rrule"
      :disabled="disabled"
    />
    <v-divider class="my-2" />
    <template v-if="getPremiumFeatures().includes('INTERNAL_ROTATION')">
      <span class="text-subtitle-1"> Intervall für Rotationen </span>
      <v-alert
        class="ma-4"
        type="info"
        color="primary"
        variant="tonal"
      >
        Hier kann optional ein anderes Intervall für Rotationen angegeben werden, falls diese getrennt von internen Einsätzen stattfinden sollen.
      </v-alert>
      <schedule-recurring
        v-model="facility.external_schedule_rrule"
        :disabled="disabled"
      />
      <v-divider class="my-2" />
    </template>
    <span class="text-subtitle-1"> Weitere Einstellungen </span>
    <v-row
      no-gutters
      class="mt-4 mx-4"
      align="center"
    >
      <v-col cols="6">
        <v-text-field
          v-model="facility.fulltime_vacancies"
          type="number"
          step="0.5"
          min="0"
          hide-details
          label="Vollzeitkräfte"
          variant="outlined"
        />
      </v-col>
      <v-col cols="6">
        <v-btn
          class="ml-4"
          color="primary"
          rounded
          size="large"
          @click="parttimeSettings(facility)"
        >
          Teilzeit konfigurieren
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="mt-8 mx-4"
    >
      <v-col class="col">
        <v-text-field
          v-model="facility.max_absent_days"
          type="number"
          step="1"
          min="0"
          label="Maximale Fehltage"
          variant="outlined"
        />
      </v-col>
      <v-col cols="auto">
        <v-tooltip
          text="Ab welcher Anzahl an Fehltagen innerhalb einer Rotation werden die Rotanden aus der automatischen Planung ausgeschlossen?"
          width="200"
        >
          <template #activator="{ props }">
            <v-icon
              class="ml-4 mt-3"
              v-bind="props"
              >mdi-help-circle-outline</v-icon
            >
          </template>
        </v-tooltip>
      </v-col>
      <v-col cols="12">
        <v-switch
          v-model="facility.external_type"
          color="primary"
          label="Erhält nur Rotanden"
          false-value="default"
          data-cy="receiveOnly"
          true-value="receive_only"
          hide-details="auto"
        ></v-switch>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    facilityId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      facility: null,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      if (this.facilityId) {
        this.facility = await this.$cms.request(
          this.$readItem('facility', this.facilityId, {
            fields: [
              'id',
              'max_absent_days',
              'schedule_rrule',
              'external_schedule_rrule',
              'external_type',
              'fulltime_vacancies',
            ],
          })
        )
      }
    },
    async submit() {
      this.facility = await this.$cms.request(
        this.$updateItem(
          'facility',
          this.facilityId,
          {
            schedule_rrule: this.facility.schedule_rrule,
            external_schedule_rrule: this.facility.external_schedule_rrule,
            external_type: this.facility.external_type,
            max_absent_days: this.facility.max_absent_days,
            fulltime_vacancies: this.facility.fulltime_vacancies,
          },
          {
            fields: [
              'id',
              'max_absent_days',
              'schedule_rrule',
              'external_schedule_rrule',
              'external_type',
              'fulltime_vacancies',
            ],
          }
        )
      )
      return true
    },
    parttimeSettings(value) {
      this.$sidebarStore.open({
        type: 'parttime',
        facility: value,
        disabled: this.currentUser.main_facility_admin ? false : true,
        image: this.facility.image,
      })
    },
  },
}
</script>
