<template>
  <app-side-bar
    v-model="open"
    :title="unitId ? 'Einheit bearbeiten' : 'Einheit hinzufügen'"
    order="-2"
  >
    <template #actions>
      <v-btn
        v-if="unitId"
        icon
        data-cy="deleteUnit"
        @click="deleteBanner = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        data-cy="saveUnit"
        icon
        @click="saveUnit"
      >
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </template>
    <v-expand-transition>
      <v-banner
        v-if="deleteBanner"
        class="bg-error"
        icon="mdi-alert"
        color="error"
      >
        Möchten Sie diese Einheit wirklich löschen?<br />
        Alle damit verknüpften Daten gehen unwiderruflich verloren.<br />
        <v-btn
          class="mt-2"
          variant="outlined"
          color="white"
          data-cy="confirmDeleteUnit"
          :loading="deleteLoading"
          @click="deleteUnit"
        >
          Ja
        </v-btn>
        <v-btn
          class="mt-2 ml-2"
          variant="outlined"
          color="white"
          @click="deleteBanner = false"
        >
          Nein
        </v-btn>
      </v-banner>
    </v-expand-transition>
    <v-container>
      <v-row>
        <v-col>
          <facility-unit-form
            v-if="unit"
            ref="facilityUnitForm"
            v-model="unit"
            :facility-id="facilityId"
          />
        </v-col>
      </v-row>
      <v-row :dense="!unitId ? true : false">
        <v-col>
          <v-btn
            color="primary"
            rounded
            size="large"
            class="px-10"
            data-cy="saveUnit"
            @click="saveUnit"
          >
            Speichern
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </app-side-bar>
</template>
<script>
export default {
  data() {
    return {
      unit: null,
      unitId: null,
      facilityId: null,
      deleteBanner: false,
      deleteLoading: false,
    }
  },
  computed: {
    open: {
      get() {
        return this.$sidebarStore.unitEdit.active
      },
      set(newValue) {
        if (!newValue) {
          this.$sidebarStore.close('unitEdit')
        }
      },
    },
  },
  async mounted() {
    this.unitId = this.$sidebarStore.unitEdit.unit ? this.$sidebarStore.unitEdit.unit.id : null
    this.facilityId = this.$sidebarStore.unitEdit.facility
      ? this.$sidebarStore.unitEdit.facility.id
      : null
    if (this.unitId) {
      this.unit = await this.$cms.request(
        this.$readItem('unit', this.unitId, {
          fields: [
            'id',
            'short_name',
            'long_name',
            'type',
            'specialities.id',
            'specialities.speciality_id',
            'takes_external_rotations',
            'minimum_vacancies',
            'maximum_vacancies',
            'priority',
            'facility',
            'rotation_types',
            'blocked_for_maternity',
          ],
        })
      )
    } else {
      this.unit = {
        facility: this.facilityId,
      }
    }
  },
  methods: {
    async saveUnit() {
      if (this.$refs.facilityUnitForm.validate()) {
        const unit = this.unit
        unit.specialities = unit.specialities.map((speciality) => {
          return { speciality_id: speciality.speciality_id }
        })
        if (unit.id) {
          const id = unit.id
          delete unit.id
          await this.$cms.request(this.$updateItem('unit', id, unit))
        } else {
          await this.$cms.request(this.$createItem('unit', unit))
        }
        this.open = false
      }
    },
    async deleteUnit() {
      this.deleteLoading = true
      this.$sidebarStore.close('unit')
      await this.$cms.request(this.$deleteItem('unit', this.unitId)).then((_) => {
        this.deleteLoading = false
        // close unit sidebar
        this.open = false
      })
    },
  },
}
</script>
