<template>
  <v-bottom-navigation
    class="mobileNavigation"
    color="primary"
    :grow="$vuetify.display.smAndDown"
    justify-content="top"
  >
    <v-btn
      v-for="(item, i) in navigation.filter((nav) => !nav.hide)"
      :key="i"
      :to="item.to"
      :style="$vuetify.display.smAndDown ? 'width: 100px' : 'width: 200px'"
    >
      <v-icon
        size="large"
        class="pb-1"
      >
        {{ item.icon }}
      </v-icon>
      <span :style="$vuetify.display.xs ? 'font-size: 11px' : ''">
        {{ item.title }}
      </span>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data() {
    return {
      value: 1,
    }
  },
  computed: {
    navigation() {
      return [
        {
          icon: 'mdi-view-dashboard-outline',
          title: 'Übersicht',
          to: '/dashboard/rotating',
        },
        /* {
            icon: 'fas fa-fw fa-book-reader',
            title: 'Fortbildungen',
            to: '/workshops/list'
          }, */
        {
          icon: 'mdi-star-outline',
          title: 'Rotationswünsche',
          to: '/schedule/requests',
          hide:
            !!this.currentUser && !!this.currentUser.position && !!this.currentUser.position.length,
        },
        {
          icon: 'mdi-calendar-range-outline',
          title: 'Ausfallzeiten',
          to: '/profile/timeperiods',
        },
        {
          icon: 'mdi-account',
          title: 'Profil',
          to: '/profile/contact',
          hide:
            !!this.currentUser && (!this.currentUser.position || !this.currentUser.position.length),
        },
      ]
    },
  },
  async mounted() {},
  methods: {},
}
</script>
<style lang="scss"></style>
