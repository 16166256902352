<template>
  <app-side-bar
    v-model="open"
    :title="formatUserName(user)"
  >
    <template #actions>
      <v-btn
        data-cy="editTrainee"
        :to="`/network/user/${user.id}?active=DASHBOARD`"
        variant="elevated"
        color="primary"
        class="mr-2 ml-2 px-8"
        append-icon="mdi-account"
        rounded
      >
        Zum Profil
      </v-btn>
    </template>
    <v-container>
      <!--span class="text-subtitle-1"> Kontaktdaten </span>
      <v-hover v-slot="{ isHovering, props }">
        <v-list-item
          :to="`/network/user/${user.id}?active=CONTACT`"
          lines="two"
          v-bind="props"
          class="mb-6"
        >
          <template #prepend>
            <profile-photo
              :key="user.id"
              class="mr-4"
              :user-id="user.id"
              readonly
              :size="50"
            />
          </template>
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{ user.phone_number }}</v-list-item-subtitle>
          <template #append>
            <v-icon
              v-if="hasRole(['ADMINISTRATIVE_STAFF']) && isHovering"
              size="small"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-list-item>
      </v-hover-->
      <v-hover v-slot="{ isHovering, props }">
        <v-list-item
          icon
          :to="`/network/user/${user.id}?active=SETTINGS`"
          v-bind="props"
          data-cy="editTraineeContactInfo"
        >
          <v-list-item-title> Stammklinik </v-list-item-title>
          <v-list-item-subtitle>
            {{
              facility
                ? formatFacilityHierarchy(facility) + ' > ' + getShortName(facility)
                : 'Keine Klinik angegeben'
            }}
          </v-list-item-subtitle>
          <v-list-item-title class="mt-3"> Angestrebter Facharzt: </v-list-item-title>
          <v-list-item-subtitle>
            {{
              targetSpeciality
                ? formatTargetSpeciality(targetSpeciality)
                : 'Keine Facharztrichtung angegeben'
            }}</v-list-item-subtitle
          >
          <template #append>
            <v-icon
              v-if="hasRole(['ADMINISTRATIVE_STAFF']) && isHovering"
              size="small"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-list-item>
      </v-hover>

      <v-container>
        <div
          v-if="totalMonths"
          class="text-h4 mb-4"
        >
          Erfahrung: {{ formatNumber(progressMonths) }} Monate
        </div>
        <div
          v-else
          class="text-h4 mb-4"
        >
          aktuelle Rotationen
        </div>
        <schedule-goals-cards
          v-if="user.id"
          v-model:progress-months="progressMonths"
          v-model:total-months="totalMonths"
          :user-id="user.id"
          teaser
          list
          dense
        />

        <div class="text-h4 mb-4 mt-8">Wünsche</div>
        <schedule-requests
          v-if="user.id"
          :key="user.id"
          class="mt-2 mb-6"
          dense
          :user-id="user.id"
          @wish-updated="$sidebarStore.setWishesUpdateStatus(true)"
        />

        <div class="text-h4 mb-4">Ausfallzeiten</div>
        <profile-time-periods
          v-if="user.id"
          :user-id="user.id"
          class="pa-0 mt-2 mb-6"
          @absence-updated="absenceUpdated"
        />

        <div class="text-h4 mb-4 mt-8">Notizen</div>
        <note-list
          v-if="user.id"
          :id="user.id"
          class="mt-2"
          collection="directus_users"
        />
      </v-container>
    </v-container>
  </app-side-bar>
</template>
<script>
export default {
  data() {
    return {
      user: {},
      facility: null,
      targetSpeciality: null,
      progressMonths: 0,
      totalMonths: 0,
    }
  },
  computed: {
    open: {
      get() {
        return this.$sidebarStore.rotatingOverview.active
      },
      set(newValue) {
        if (!newValue) {
          this.$sidebarStore.close('rotatingOverview')
        }
      },
    },
  },
  async mounted() {
    this.user = this.$sidebarStore.rotatingOverview.user
    const physicianResponse = await this.getPhysician(this.user.id, [
      'id',
      'target_speciality.id',
      'target_speciality.parent_speciality.name',
      'target_speciality.child_speciality.name',
      'user.facilities.facility_id.id',
      'user.facilities.facility_id.name',
      'user.facilities.facility_id.short_name',
      'user.facilities.facility_id.show_in_hierarchy',
      'user.facilities.facility_id.parent_facility.id',
      'user.facilities.facility_id.parent_facility.name',
      'user.facilities.facility_id.parent_facility.short_name',
      'user.facilities.facility_id.parent_facility.show_in_hierarchy',
      'user.facilities.facility_id.parent_facility.parent_facility.id',
      'user.facilities.facility_id.parent_facility.parent_facility.name',
      'user.facilities.facility_id.parent_facility.parent_facility.short_name',
      'user.facilities.facility_id.parent_facility.parent_facility.show_in_hierarchy',
      'user.facilities.facility_id.parent_facility.parent_facility.parent_facility.id',
      'user.facilities.facility_id.parent_facility.parent_facility.parent_facility.name',
      'user.facilities.facility_id.parent_facility.parent_facility.parent_facility.short_name',
      'user.facilities.facility_id.parent_facility.parent_facility.parent_facility.show_in_hierarchy',
    ])
    if (physicianResponse.user.facilities && physicianResponse.user.facilities.length) {
      this.facility = physicianResponse.user.facilities[0].facility_id
    }
    if (physicianResponse.target_speciality) {
      this.targetSpeciality = physicianResponse.target_speciality
    }
  },
  methods: {
    formatTargetSpeciality(targetSpeciality) {
      let text = targetSpeciality.parent_speciality.name
      if (targetSpeciality.child_speciality) {
        text = text + ' - ' + targetSpeciality.child_speciality.name
      }
      return text
    },
    async absenceUpdated(user) {
      this.$sidebarStore.setAbsencesUpdateStatus(true)
      if (user) {
        await this.$sidebarStore.close('rotatingOverview')
        await this.$sidebarStore.open({ type: 'rotatingOverview', user: user })
      }
    },
  },
}
</script>
