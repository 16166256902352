<template>
  <v-dialog
    v-model="open"
    data-cy="twoFactorAuthDialog"
    :title="
      !currentUser.tfa_secret
        ? 'Zwei-Faktor-Authentifizierung aktivieren'
        : 'OTP zum deaktivieren der 2FA eingeben'
    "
    width="600"
    close-on-back
    eager
  >
    <v-card
      class="pb-5"
      append-icon="$close"
    >
      <template #append>
        <v-btn
          icon="$close"
          variant="text"
          data-cy="closeHelpDialog"
          @click="open = false"
        ></v-btn> </template
      ><v-card-item class="pt-0">
        <v-row>
          <v-col>
            <v-card-text class="text-h2 text-center pt-0 mt-0">
              Zwei-Faktor-Authentifizierung
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-item>

      <template v-if="!currentUser.tfa_secret">
        <v-window
          v-model="step"
          class=""
          style="box-shadow: none"
        >
          <v-window-item :value="1">
            <v-card-text
              class="text-body-1 text-center"
              data-cy="helpText"
            >
              <v-row>
                <v-col cols="12">
                  <div class="text-body-1">
                    Geben Sie Ihr Passwort ein, um die Zwei-Faktor-Authentifizierung zu aktivieren.
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-form
                    ref="passwordForm"
                    validate-on="submit"
                    @submit.prevent
                  >
                    <v-text-field
                      ref="password"
                      v-model="password"
                      label="Account Passwort"
                      variant="outlined"
                      :rules="passwordErrors"
                      required
                      type="password"
                      data-cy="accountPassword"
                      @keyup.enter="generateSecretCode"
                    />

                    <v-btn
                      class="px-15 mt-5"
                      size="large"
                      rounded
                      color="primary"
                      type="submit"
                      data-cy="furtherButton"
                      @click="generateSecretCode()"
                    >
                      Weiter
                    </v-btn>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
          </v-window-item>
          <v-window-item
            :value="2"
            class="text-center"
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div class="text-body-1">
                    Scannen Sie den QR-Code in Ihrer Authenticator-App oder kopieren Sie den
                    Schlüssel manuell und fügen Sie ihn so zu Ihrer App hinzu.
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <template v-if="secretCode.code">
                <vue-qr
                  :text="secretCode.link"
                  :size="200"
                  :components="{
                    cornerAlignment: {
                      protectors: true,
                    },
                  }"
                />
                <v-row
                  justify="center"
                  align="center"
                  dense
                >
                  <v-col
                    cols="auto"
                    class="d-flex align-center justify-center"
                  >
                    <h4 data-cy="secretCode">{{ secretCode.code }}</h4>
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <v-icon
                          class="ml-2"
                          size="small"
                          icon="mdi-content-copy"
                          v-bind="props"
                          @click="copySecretCode"
                        />
                      </template>
                      <span>In die Zwischenablage kopieren</span>
                    </v-tooltip>
                  </v-col>
                  <v-col
                    v-if="secretCodeCopied"
                    cols="12"
                    class="text-center text-grey-darken-1 text-subtitle-1"
                  >
                    Schlüssel wurde in die Zwischenablage kopiert.
                  </v-col>
                </v-row>
                <v-btn
                  class="mt-5 px-15"
                  size="large"
                  rounded
                  color="primary"
                  data-cy="furtherButton"
                  @click="step = 3"
                >
                  Weiter
                </v-btn>
              </template>
            </v-container>
          </v-window-item>
          <v-window-item :value="3">
            <v-card-text
              class="text-body-1 text-center"
              data-cy="helpText"
            >
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div class="text-body-1">
                      Geben Sie das einmalige Passwort ein, um die Einrichtung von 2FA
                      abzuschließen.
                    </div>
                  </v-col>
                </v-row>
              </v-container>
              <v-form ref="oneTimePasswordCodeForm">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        ref="oneTimePasswordCode"
                        v-model="oneTimePasswordCode"
                        label="Einmaliges Passwort (OTC)"
                        variant="outlined"
                        :rules="oneTimePasswordCodeErrors"
                        data-cy="oneTimePasswordCode"
                        @keyup.enter="enableTfa"
                      />

                      <v-btn
                        class="mt-5 px-15"
                        size="large"
                        rounded
                        color="primary"
                        data-cy="enableTfa"
                        @click="enableTfa"
                      >
                        Aktivieren
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-window-item>
        </v-window>
      </template>
      <template v-else>
        <v-form ref="disableOneTimePasswordCodeForm">
          <v-card-text
            class="text-body-1 text-center"
            data-cy="helpText"
          >
            <v-row>
              <v-col cols="12">
                <div class="text-body-1">
                  Geben Sie Ihr einmaliges Passwort (OTC) ein, um die Zwei-Faktor-Authentifizierung
                  zu deaktivieren.
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-form
                  validate-on="submit"
                  @submit.prevent
                >
                  <v-text-field
                    ref="disableOneTimePasswordCode"
                    v-model="oneTimePasswordCode"
                    :rules="oneTimePasswordCodeErrors"
                    label="Einmaliges Passwort (OTC)"
                    variant="outlined"
                    @keyup.enter="disableTfa"
                  />

                  <v-btn
                    class="mt-1 px-15"
                    size="large"
                    rounded
                    color="error"
                    @click="disableTfa()"
                  >
                    2FA deaktivieren
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { required, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { generateTwoFactorSecret, enableTwoFactor, disableTwoFactor } from '@directus/sdk'

export default {
  components: {
    vueQr,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      password: '',
      passwordErrors: [
        (v) => !!v || 'Bitte geben Sie ein Passwort ein.',
        (v) => (v && v.length >= 8) || 'Bitte geben Sie ein Passwort mit mindestens 8 Zeichen ein.',
      ],
      oneTimePasswordCode: null,
      oneTimePasswordCodeErrors: [
        (v) => !!v || 'Bitte geben Sie ein Passwort ein.',
        (v) => (v && v.length === 6) || 'Bitte geben Sie ein Passwort 6 Zeichen ein.',
      ],

      showPassword: false,
      step: 1,
      qrCodeLink: null,
      secretCode: {},
      slides: [],
      secretCodeCopied: false,
    }
  },

  computed: {
    open: {
      get() {
        return this.$sidebarStore.twoFactorAuth.active
      },
      set(newValue) {
        if (!newValue) {
          this.$sidebarStore.close('twoFactorAuth')
        }
      },
    },
  },
  methods: {
    async generateSecretCode() {
      this.$refs.passwordForm.validate().then(async (res) => {
        if (res.valid) {
          try {
            await this.$cms.request(generateTwoFactorSecret(this.password)).then((res) => {
              this.secretCode.code = res.secret
              this.secretCode.link = res.otpauth_url
              this.step = 2
            })
          } catch (error) {
            this.step = 1
            this.$notificationStore.set({
              title: 'Passwort ungültig',
              text: 'Bitte überprüfen Sie Ihr Passwort.',
              type: 'error',
            })
          }
        }
      })
    },
    async enableTfa() {
      this.$refs.oneTimePasswordCodeForm.validate().then(async (res) => {
        if (res.valid) {
          try {
            await this.$cms
              .request(enableTwoFactor(this.secretCode.code, this.oneTimePasswordCode))
              .then(() => {
                this.$store.setTfa(this.secretCode.code)
                this.$notificationStore.set({
                  text: 'Zwei-Faktor-Authentifizierung erfolgreich aktiviert.',
                  type: 'success',
                })
                this.$sidebarStore.close('twoFactorAuth')
              })
          } catch (error) {
            this.$notificationStore.set({
              title: 'Vorgang fehlgeschlagen',
              text: 'Ihre Anfrage war ungültig. Bitte überprüfen Sie Ihre Eingaben.',
              type: 'error',
            })
          }
        }
      })
    },
    async disableTfa() {
      this.$refs.disableOneTimePasswordCodeForm.validate().then(async (res) => {
        if (res.valid) {
          try {
            await this.$cms.request(disableTwoFactor(this.oneTimePasswordCode)).then(() => {
              this.$store.setTfa(null)
              this.$notificationStore.set({
                text: 'Zwei-Faktor-Authentifizierung erfolgreich deaktiviert.',
                type: 'success',
              })
              this.$sidebarStore.close('twoFactorAuth')
            })
          } catch (error) {
            this.$notificationStore.set({
              title: 'Vorgang fehlgeschlagen',
              text: 'Ihre Anfrage war ungültig. Bitte überprüfen Sie Ihre Eingaben.',
              type: 'error',
            })
          }
        }
      })
    },
    copySecretCode() {
      navigator.clipboard.writeText(this.secretCode.code).then(() => {
        this.secretCodeCopied = true
      })
    },
  },
}
</script>
